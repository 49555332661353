.homepage-main{
	padding: 30px;
	max-width: 1200px;
	margin: 0 auto;
	width: 100%;
}

section{
	margin-bottom: 60px;
	display: flex;
	@media screen and (max-width: 768px) {
	    flex-direction: column;
	    align-items: center;
	}		
}

.intro-image{
	height:  200px;
	width: 200px;
	border-radius: 6px;
	margin-right: 30px;
	@media screen and (max-width: 768px) {
	    margin: 0;
	}		
}

.intro-content{
	padding-top: 10px;
}

.title{
	font-family: 'Roboto', sans-serif;
	font-size: 24px;
	margin-bottom: 15px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@media screen and (max-width: 768px) {
	    text-align: center;
	}	
}

.paragraph{
	font-family: 'Roboto Slab', serif;
	font-weight: 300;
	font-size: 13px;
	line-height: 1.6em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	white-space: pre-line;
	b {
		font-weight: 700;
	}
	a {
		text-decoration: underline;
		color:blue;
		cursor: pointer;
	}
}










.office-hours-image{
	box-shadow: 0 2px 10px 0 rgb(16 36 94 / 20%);
	border-radius: 6px;
	overflow: hidden;
	cursor: pointer;
	margin-left: 30px;
    height: auto;
	@media screen and (max-width: 768px) {
	    margin:0 ;
	    margin-top: 40px;
	    max-width: 100%;
	}	
}




.curriculum-vitae-pdf{
	box-shadow: 0 2px 10px 0 rgb(16 36 94 / 20%);
	border-radius: 6px;
	overflow: hidden;
	max-width: 460px;
	margin-right: 30px;
	@media screen and (max-width: 768px) {
	    margin: 0;
	    margin-top: 40px;
	    order: 2;
	}	
}







