.page-container{
	position: relative;
	height: fit-content;
	// &:hover>.page-controls{
	// 	opacity: 1;
	// }
}

.pdf-canvas{
	width: 100%;
}

.page-controls{
	position: absolute;
    bottom: 5%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    // opacity: 0.5;
    // transition: opacity ease-in-out 0.4s;
}
.page-control-item{
    background: white;
    box-shadow: 0 18px 32px 0 rgb(16 36 94 / 20%);
    border-radius: 4px;

    &>.button{
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 44px;
	    height: 44px;
	    background: white;
	    border: 0;
	    font: inherit;
	    font-size: 0.8em;
	    cursor: pointer;

	    &:first-child{
	    	border-top-left-radius: 4px;
    		border-bottom-left-radius: 4px;
	    }

	    &:last-child{
	    	border-top-right-radius: 4px;
    		border-bottom-right-radius: 4px;
	    }

	    &:hover:not([disabled]){
	    	background-color: #e6e6e6;
	    }
	}
}

.page-controls span{
	font: inherit;
    font-size: 0.8em;
    padding: 0 0.5em;
	font-family: 'Roboto', serif;
	font-weight: 500;
	font-size: 11px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}