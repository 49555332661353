.header {
  height: 58px;
  width: 100%;
  transition: all 600ms ease;
}

.header-fixed {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
  background-color: #FFF;
  border-bottom: solid 1px #e1e1e1;
}

.navbar {
  width: 100%;
  max-width: 1200px;
  height: 58px;
  margin:0 auto;
  background-color: white;
  padding: 0 30px;

  &>ul{ 
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    &>li{
      margin: 2px 15px 0 0;
      justify-self: flex-end;
      &:last-child{
        margin: 2px 0 0 0;
      }
      &.break{
        margin-right: auto;
      }
    }
  }
}

.navbar-link {
  font-family: 'Roboto', sans-serif;
  color: #252525;
  opacity: 0.5;
  font-weight: 600;
  transition: 150ms ease;
  cursor: pointer;
  font-size: 11px;

  &>svg{
    height: 20px;
    width: 20px;
  }

  &:hover {
    opacity: 1;
  }
}